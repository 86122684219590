/* Footer Style Start*/

.footer {
    background-color: #051B13;
    text-align: start;
  }
  
  .footer__top {
    /* padding: 79px 0px 111px; */
    padding: 60px 10px 20px 10px;
  }
  
  .footer__top-section {
    margin-bottom: 50px;
  }
  
  @media only screen and (min-width: 854px) {
    .footer__top-section {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-width: 768px) {
    .padding_sec{
        padding-right: 0px!important;
        }
    .footer{
      text-align: center;
    }
  }
  
  @media only screen and (max-width: 495px) {
    .footer__top {
      padding: 79px 20px 111px !important;
    }
  }
  
  .footer-socialmedia {
    list-style: none;
    padding-left: 0px;
    display: flex;
    margin-bottom: 0px;
  }
  
  .footer-socialmedia__item:not(:last-child) {
    margin-right: 11px;
  }
  
  .footer-socialmedia__item-link {
    width: 34px;
    height: 34px;
    padding: 10px 17px;
    background-color: #ffffff;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-logo-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
  }
  
  /* Footer Style End*/
  
/*   
  .ft {
    width: 100%;
    height: 600px;
    background-color: #051B13;
  }
  
  .footer__flex {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: space-between;
  }
  
  .footer__flex_content {
    width: 24%;
    height: 500px;
  }
   */
  
  .footer__logo {
    padding: 0 0 16px;
    width: 73px;
    height: auto;
  }
  
  .footer_a_tag {
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    color: #CFCFCF;
  }
  
  .footer__links_heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: #FFFFFF;
    padding: 0 0 30px;
  }
  
  .footer__socialmedia_logos {
    padding: 73px 0px;
  }
  
  .footer__paragraph {
    color: #CFCFCF;
    font-size: 16px;
    line-height: 30px;
    text-decoration: none;
    font-weight: 500;
  
  }
  .footer__paragraph2 {
    color: #CFCFCF;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;
    margin-bottom: 0;
  }
  .footer__paragraph3 {
    color: #76FF55;
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
    font-weight: 400;
  }
  
  .footer__copyrigh_paragraph {
    font-size: 14px;
    line-height: 10px;
    color: #fff;
    text-align: center;
    padding: 37px 0px;
    margin-bottom: 0;
  }
  
  .footer__horizontal_line {
    border-top: 1px solid #2B6259;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  
  .footer__download {
    padding: 0px 11px;
  }
  .padding_sec{
    padding-right: 100px;
  }
  .footer_a_tag:hover{
    color: blue;
  }
  @media (max-width: 736px) {
    .footer-logo-section {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .footer-socialmedia {
      display: flex;
      justify-content: center; /* Center horizontally */
      flex-direction: row;     /* Keep icons in one row */
    }
    
    .footer__top-section {
      text-align: center;
    }
  
    .footer-socialmedia__item {
      margin: 0 10px;
    }
    .special_mobile{
      padding: 0!important;
    }
  }