.slide_grid{
    border:  1px solid #d0d5dd;
    border-radius: 10px;
    padding: 10px;
    height: 208px;
    background-color: #F1FAF7;
    margin-top: 15px;
}
.number{
    font-size: 30px;
    font-weight: 600;
}
.travel_name{
font-size: 16px;
}