.home-1-left {
    text-align: start;
}

.line_1 {
    display: inline-block;
    width: 50px;
    height: 2px;
    background-color: #00B27C;
    margin-right: 10px;
}

.home_welcome {
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
    color: #00B27C;
}

.home_1_head {
    font-size: 54px;
    font-weight: 700;
    line-height: 78px;
    color: #002116;
}

.home_1_head2 {
    font-size: 54px;
    font-weight: 400;
    line-height: 78px;
    color: #097F5C;
}

.home_1_para {
    font-size: 20px;
    color: #403A3A;
    line-height: 34px;
    font-weight: 400;
}

.btn_common {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    padding: 14px 40px;
    background: linear-gradient(256.22deg, #00B27C -8.12%, #002E20 123.47%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 8px;
    transition: all 0.5s;
    border: none;
}

.btn-common:hover {
    color: #FFFFFF;
    background-color: #118b58;
}

.home_read_more {
    color: #00B27C;
    font-size: 18px;
    font-weight: 500;
    margin-left: 15px;
}

.home-1-left {
    position: relative;
    /* Parent container positioned relative */
}

.home-money-container {
    position: absolute;
    /* Make the image container absolute */
    bottom: -40px;
    /* Adjust the position as needed */
    right: 0;
}

.home_money {
    width: 112px;
    /* Adjust the size of the image */
    height: auto;
}

.home_container_1 {
    margin-bottom: 40px;
}

.image_bac {
    /* background-image: url(../../assets/sec_2_img.png); */
    background-color: #F1FAF7;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* height: 100vh; */
}
.sec_3_image_bac {
  background-image: url(../../assets/wave5.png);
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
 
}

.home_2_title {
    font-size: 42px;
    line-height: 60px;
    font-weight: 600;
    color: black;
}

.home_2_p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: black;
}

.btn_common2 {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    padding: 12px 70px;
    background-color: #01A070;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 47px;
    transition: all 0.5s;
    border: none;
}

.btn-common2:hover {
    color: #FFFFFF;
    background-color: #118b58;
}

/* .image_bac2 {
    background-image: url(../../assets/img001.JPG);
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
} */
.image_bac2 {
  position: relative;
  width: 100%;   /* Full width of the parent container */
  height: auto;  /* Adjust height automatically to maintain aspect ratio */
  overflow: hidden;  /* Ensure no overflow */
  background-repeat: no-repeat;
  background-position: center;
}

.img001_resize {
  width: 100%;   /* Ensure the image takes the full width of the container */
  height: auto;  /* Maintain the aspect ratio */
  object-fit: cover;  /* Ensures the image covers the area without being distorted */
  object-position: center;  /* Keeps the image centered */
}
/* .image_bac3 {
  background-image: url(../../assets/img002.JPG);
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
} */
.image_bac3 {
  position: relative;
  width: 100%;   /* Full width of the parent container */
  height: auto;  /* Adjust height automatically to maintain aspect ratio */
  overflow: hidden;  /* Ensure no overflow */
  background-repeat: no-repeat;
  background-position: center;
}
.img002_resize {
  width: 100%;   /* Ensure the image takes the full width of the container */
  height: auto;  /* Maintain the aspect ratio */
  object-fit: cover;  /* Ensures the image covers the area without being distorted */
  object-position: center;  /* Keeps the image centered */
}

.sec_4_right {
    padding: 18px 56px !important;
    text-align: start;
}

.sec_4_h1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #00B27C;
    text-transform:uppercase;
}
.sec_4_p1_sub{
    font-size: 14px;
    font-weight: 400;
    line-height: 10px; 
}

.sec_4_h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 58px;
    color: #000000;
}

.sec_4_p1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #64607D;
}



.sec_6_grid {
    padding: 20px 40px;
    width: 100%;
    border: 1px solid #DEE1E6;
    text-align: start;
    border-radius: 20px;
}

.sec_icon {
    width: 32px;
    height: auto;
}

.sec_6_bac {
    background-color: #E9ECF2;
    display: flex;
    justify-content: center;
    /* Horizontal centering */
    align-items: center;
    /* Vertical centering */
    height: 60px;
    /* Ensure the background div has a height */
    width: 60px;
    /* Optional, set a width for the container */
    position: relative;
    /* Optional, if the background is relative to its parent */
    border-radius: 5px;
}

.among {
    font-size: 34px;
    font-weight: 600;
    line-height: 46px;
}
.sec_among_p{
  color: #64607D;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin-left: 16px;
}
.image_bac_sec_7 {
    background: linear-gradient(90deg, #016244 -2.99%, #01B37D 102.6%);
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 40px 70px;
}
.sec_icon_man{
    width: 109px;
    height: auto;
}
.sec_green_p1{
    font-size: 34px;
    font-weight: 600;
    line-height: 48px;
    color: #FFFFFF;
}
.sec_green_p2{
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
}
.btn_common4 {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    padding: 12px 40px;
    background: linear-gradient(271.37deg, #8DBC5F -10.01%, #328565 44.81%, #23A078 118.35%);
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 40px;
    transition: all 0.5s;
    border:1px solid #FFFFFF ;
}

.btn-common4:hover {
    color: #FFFFFF;
    background-color: #118b58;
}
.btn_common5 {
    font-weight: 500;
    font-size: 16px;
    color: #118b58;
    padding: 12px 40px;
    background:#FFFFFF;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 40px;
    transition: all 0.5s;
    border:1px solid #FFFFFF ;
    margin-left: 15px;
}

.btn-common5:hover {
    color: #FFFFFF;
    background-color: #118b58;
}
.sec_7_btn{
text-align: end;
}
.sec_3_right{
    text-align: start;
    /* height: 100vh; */
    z-index: 2;
}
.convert_grid{
   border: 1px solid #D8D8D8; 
   border-radius: 18px;
   width: 100%;
   height: 100%;
   padding: 10px 30px;
   text-align: start;
}
 /* input icon search */
 .input-with-icon {
    position: relative;
    /* width: 100%;  */
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px; /* Adjust the left position based on your design */
    transform: translateY(-50%);
    color: #000000; /* Optional: Change the color of the icon */
  }
  
  .search-input {
    width: 100%;
    padding-left: 187px;
    padding-right: 10 px;
    height: 42px;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    font-size: 12px;
    box-sizing: border-box;
    background: #F8F8F8;
    font-size: 20px;
    color: black;

  }
  .dh_data_head{
    color: #6D6B6B;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 12px;
  }

  .flag_convert{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
    overflow: hidden;
  }
  .flag_img{
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  .arrow_icon{
    position: absolute;
    top: 20%;
    left: 76px;
    cursor: pointer;
  }
  .input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
  }
  .flag_img {
    width: 100%;
    height: 100%;
  }
   .country_name {
    margin-left: 10px;
    cursor: pointer;
    position: absolute;
    left: 28px;
}
  .dropdown_menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    z-index: 1;
    padding: 5px;
    list-style: none;
  }
  
  .dropdown_menu li {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown_menu li:hover {
    background: #F8F8F8;
  }
  .con_p{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
  }
  .border_line{
    border: 1px solid #DDDDDD ;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .home-money-container2 {
    position: absolute;
    /* bottom: -83px; */
    right: 72px;
    top: 122px;
}
.card_home{
  height: 60px; /* Set a uniform height */
  object-fit: contain; /* Ensure the image retains its aspect ratio */
  margin: 0 10px;

}
/* animation */
/* AnimatedText.css */

.animated-text {
  display: inline-block;
  /* font-size: 2rem;
  font-weight: bold; */
  letter-spacing: 1px;
}

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 6s ease forwards infinite;
  animation-fill-mode: forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.sec_1_start_button{
  text-align: start;
}
.sec_2 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: #F1FAF7;
  padding: 40px;
}
.home_mobile{
  display: none;
  padding-bottom: 30px;
  /* height: 20vh; */
}
.mobile_not{
  display: block;
}
.sec_4_image {
  order: 2; 
}

.sec_4_right {
  order: 1;
}


















  
  
  























































/* .sec_bac_wa{
    background-image: url(../../assets/vawe.png);
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

} */




.sec_3 {
    position: relative; 
    overflow: hidden; 
    height: 100vh;
  }
  
  .sec_bac_wa {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    /* background-image: url(../../assets/vawe.png); */
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    z-index: -1; /* Places the image behind the content */
  }
  
  .sec_3 .container {
    position: relative;
    z-index: 1; /* Ensures the content is on top of the background image */
  }
  
  .sec_3_right {
    color: #fff; /* Optional: Ensure text is readable on the background */
  }
  .sec_3_box{
    position: absolute;
    top: 50vh;
  }
  .box_img{
    width: 64px;
    height: auto;
  }
  .sec_bac_wa2{
    /* background-image: url(../../assets/Process.png); */
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
  }

  
  .container_new {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 9px;
  }
  
  /* Left-hand card styling */
  .left-card {
    width: 30%;
    padding: 20px;
    background-color: #f8f9fa;
    border: 2px solid #28a745;
    border-radius: 10px;
    text-align: left;
  }
  
  .left-card h2 {
    font-size: 24px;
    color: #28a745;
    margin-bottom: 10px;
  }
  
  .left-card p {
    color: #6c757d;
  }
  
  /* Wave container and positioning */
  .wave-container {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  
  .wave {
    width: 100%;
    height: 300px;
  }
  
  circle {
    fill: #C4C4C4;
    box-shadow: white;
  }
  
  text {
    font-size: 18px;
    fill: #28a745;
    font-family: Arial, sans-serif;
  }
  .key_benefits{
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }
  .responsive_dec{
    display: block;
  }
  .responsive_mob{
    display: none;
  }
  .sec_4_p1_sub_li{
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
  }
  @media screen and (max-width: 767px) {
    .container_new {
      display: none;
    }
    .image_bac3 {
      background-image: url(../../assets/mobile/img02.jpg);
        background-size: contain;
        height: 500px;
        width: 103%;
    }
    .image_bac2 {
      background-image: url(../../assets/mobile/img01.jpg);
      background-size: contain;
      height: 500px;
      width: 97%;
  }
  .img001_resize{
    display: none;
  }
  .img002_resize{
    display: none;
  }
  /* .sec_3_image_bac {
    background-image: url(http://localhost:3001/static/media/wave3.b40e65d….png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 40vh;
} */
.home-1-left {
  text-align: center;
}
.home-money-container,.home-money-container2{
  display: none;
}
.sec_1_start_button{
  text-align: -webkit-center;
}
.home_1_head {
  font-size: 35px;
  line-height: 58px;
}
.home_1_head2,.sec_4_h2 {
  font-size: 35px;
  line-height: 45px
}

.sec_4_right {
  padding: 10px 31px !important;
  text-align: center;
}
.why_are{
  margin-bottom: 10px;
}
.ask_man{
  display: none;
}
.sec_7_btn {
  text-align: center;
}
.btn_common5 {
  margin-left: 6px;
}
.sec_green_p1,.sec_green_p2,.btn_common4,.btn_common5{
  margin-bottom: 20px;
}
.image_bac_sec_7 {
  padding: 40px;
}
.sec_3_image_bac {
  
  height: 36vh!important;
}
.sec_2 {
  margin-top: 20px;
  margin-bottom: 0;
  background: #F1FAF7;
  padding: 40px 10px;
}
.home_mobile{
  display: block;
  padding-bottom: 50px;
}
.mobile_not{
  display: none;
} 
.sec_4_image {
  order: 1; 
}

.sec_4_right {
  order: 2;
}
.sec_3_right{
  text-align: center;
}
.home_container_1 {
  margin-bottom:0;
}
.sec_3{
  padding: 40px 10px 0 10px;
  margin-bottom: 0!important;
  height: auto;
}
.animated-text {
  position: relative;
  display: inline-block;
  width: 256px;
  margin-left: -133px;
  margin-right: 12px;
}
.debit,.credit {
  margin-top: -20px!important;
}
.animated-text {
 
  width: 261px!important;
}
.responsive_dec{
  display: none;
}
.responsive_mob{
  display: block;
}
  }




  .home_1_head2 {
    display: inline-flex;
    align-items: center;
    font-size: 54px;
  }
  
  .animated-text {
    position: relative;
    display: inline-block; /* Keep it inline while allowing positioning */
    width: 154px; /* Width to accommodate both words */
  }
  
  .word {
    position: absolute;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  /* Keyframe animation to alternate words */
  @keyframes swapWords {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    20% {
      opacity: 1;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    80% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  .credit {
    animation: swapWords 4s infinite;
    margin-top: -35px;
  }
  
  .debit {
    animation: swapWords 4s infinite;
    animation-delay: 2s; /* Delayed to alternate after Credit */
    margin-top: -35px;
  }
  


















  