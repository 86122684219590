.main-money-transfer-div {
    /* height: 90vh; */
    /* width: 70%; */
    padding: 30px 0 50px 0;
  }
  @media (max-width: 432px) {
    .main-money-transfer-div {
      width: 90%;
    }
  }
.money-transfer-main-div {
  /* width: 320px; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 432px) {
    .money-transfer-main-div {
      width: 100%;
    }
  }
.vh-100 {
  height: 100vh;
}
.vw-1001 {
  width: 100%;
}

.arrow-left {
  cursor: pointer;
}
.transfer-topic {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  width:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.label-text{
color: #6D6B6B;
font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 10px 0px 5px 0px;
}
.div-type-input {
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  /* background: lightgreen; */
  /* padding-top: 10px;*/
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  /* Shadow/xs */
  cursor: "pointer";
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.div-type-input-money {
    border: none;
    outline: none;
    text-align: left;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    background-color: #F8F8F8;
    border: 1px solid var(--gray-300, #d0d5dd);
    /* background: lightgreen; */
    /* padding-top: 10px;*/
    padding:0px 10px 0px 10px; 
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
  .send-vale-text{
    color: #000000;
    font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  }
  
  .details-text{
    color: #6D6B6B;
    font-size: 14px;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      margin: 10px 0px 10px 0px;
    }

    .details-text-value{
        color: #000000;
        font-size: 14px;
          font-weight: 400;
          font-family: "Poppins", sans-serif;
          margin: 10px 0px 10px 0px;
        }
        .devider{
            width: 100%;
            border: 1px solid #DDDDDD
        }
        .div-type-input-textarea {
            border: none;
            outline: none;
            text-align: left;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-self: stretch;
            border-radius: 8px;
            background-color: #F8F8F8;
            border: 1px solid #D0D5DD;
            /* background: lightgreen; */
            /* padding-top: 10px;*/
            padding:5px 5px 5px 5px; 
            font-size: 14px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
          .continue-button{
            width: 100%;
            height: 44px;
            border-radius: 8px;
            background-color: #071614;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
            font-family: 'Inter', sans-serif;
            margin: 25px 0 10px 0;
          }
          .disabled-area {
            pointer-events: none;
            color: gray;
            opacity: 50%;
            /* Any other styles you want to apply to disabled links */
          }