.navbar{
    width: 100%;
    height: 112px;
    background-color:#FFFFFF ;
    /* border-bottom: 1px solid #E3E3E3; */
}
.navbar {
    justify-content: space-between;
  }
  
  .logout {
    background-color: #0A8A63; 
    border: none;
    padding: 7px 14px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    color: white;
  }
  
  .logout:hover {
    background-color: #59B931; 
  }
  .log_in{
    font-size: 16px;
    color:#000000 ;
    font-weight: 400;
    margin-right: 10px;
  }
  .container-fluid{
    padding-left: 45px!important;
    padding-right: 45px!important;
  }
  .link_text{
    text-decoration: none;
  }
  .login_responsive{
    display: none;
  }
  @media screen and (max-width: 767px) {
    .login_responsive{
      display: block;
    }
  }
  



